// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const isIE =
  window.navigator.userAgent.indexOf('MSIE') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

import { commonEnvironment } from './environment.common';

export const environment = {
  debug: true,
  production: false,
  logLevel: 4,
  common: commonEnvironment,
  // host: 'https://localhost:8086/webispettori/',
  host: 'https://eurofins-wa-dev.valuepartners.com/backend/',
  // mapsApiKey: 'AIzaSyBga06JuiuU0HOespEZSpXDKcb79LSADmA',
  mapsApiKey: 'AIzaSyDtWHeYmgw4BZ67pOBNW4r1tEeZrB3t-Bo', // prod key
  dev: true,
  msal: {
    auth: {
      clientId: '6db803db-ae8c-482b-b7da-6dec03883f79',
      authority: 'https://login.microsoftonline.com/73bc36a8-eaba-47c0-9beb-3c545ee0214f',
      redirectUri: 'https://eipsmconnector-exage.msappproxy.net/'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE // Set to true for Internet Explorer 11
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
